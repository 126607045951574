import React, { useEffect, useRef, useState } from "react";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import LogroForm from "../components/Forms/LogroForm.js";  // Asegúrate de crear este componente
import Swal from "sweetalert2";
import utils from '../utils/generalFunctions';
import Loading from "../components/Loading.js";
import JornadaAnioPeriodoNivelCurso from "../components/Filters/JornadaAnioPeriodoNivelCurso.js";
import academicService from "../services/academicService.js";
import { toast } from 'react-toastify';
import estudiantesService from "../services/estudiantesService.js";
import moment from "moment";
import scheduleService from "../services/scheduleService.js";
import HorarioForm from "../components/Forms/HorarioForm.js";

const Horario = () => {

    const initData ={
        schoolYearId: "0",
        journeyId:"0",
        periodId:"",
        educationLevelId: "0",
    };
    const [formData, setFormData] = useState(initData);
    const [periodos, setPeriodosList] = useState([]);
    const [datosBusqueda, setDatosBusqueda] = useState([]);
    const [horario, setHorario] = useState([]);
    const filteReference = useRef(null);
    const [fullscreenXlModal, setFullscreenXlModal] = useState(false);
    const [selectedHorario, setSelectedHorario] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const toggleShow = () => {
        setFullscreenXlModal(!fullscreenXlModal);
        setSelectedHorario(null); 
    }
    
    const openAddingModal = async (horario) => {
        setSelectedHorario(horario);
        setFullscreenXlModal(true);
    };

    const deleteHorarioById = async(horarioId) => {
        Swal.fire({
            title: '¿Está seguro que desea eliminar este horario?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try{
                    await scheduleService.deleteClassScheduleById(horarioId);  
                    Swal.fire(
                        '¡Felicidades!',
                        'Horario eliminado exitosamente!',
                        'success'
                    );
                    BuscarHorario();
                }catch(error){
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intente más tarde",
                        "error"
                    );
                }
            }
        })
    }

    const updateForm = async (data) => {
        if (data.schoolYearId!==formData.schoolYearId){   
          await cargarPeriodos(data.schoolYearId);
        }
        setFormData(prevState => ({
            ...prevState,
            ...data
          }));
    }

    const cargarPeriodos = async (schoolYearId) => {
        try {
            const schoolYear = await academicService.getYearById(schoolYearId);              
            if(schoolYear){
                setPeriodosList(schoolYear.periods)
            }            
        } catch (error) {
                console.error("Error al obtener información de año escolar:", error);
        }
    }

    const BuscarHorario = async (e) =>{
          if(e) e.preventDefault();
          setShowLoading(true);
          setHorario([]);
            const requiredFields = ['schoolYearId', 'journeyId', 'periodId','educationLevelId','courseId'];
            for (let field of requiredFields) {
                if (!formData[field] || formData[field]==='0') {
                    toast.error("Por favor, completa todos los campos obligatorios.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    return;
                }
            }
            setDatosBusqueda([]);
            try {
               const copyData = utils.copy(formData);
               copyData.periodName = periodos.find(p=>String(p.academicPeriodId)===String(copyData.periodId))?.periodName??"";
               const years = filteReference.current?.getSchoolYears();
               if(years){
                    copyData.year = years.find(p=>String(p.schoolYearId)===String(copyData.schoolYearId))?.year??"";
               }
               const journeys = filteReference.current?.getJourneys();
               if(journeys){
                    copyData.journey = journeys.find(p=>String(p.journeyId)===String(copyData.journeyId))?.name??"";
               }
    
               const courses = filteReference.current?.getCursos();
               if(courses){
                    copyData.course = courses.find(p=>String(p.courseId)===copyData.courseId)?.name??"";
               }
    
               const educationLevels = filteReference?.current?.getEducationLeves();
               if (educationLevels.length > 0) {
                    copyData.evaluationTypeId = educationLevels.find((c) => String(c.educationLevelId) === String(copyData.educationLevelId))?.evaluationTypeId;
               }

               setDatosBusqueda(copyData);
              const data = await estudiantesService.getEstudianteHorario(copyData.courseId, copyData.schoolYearId); // Obtenemos los estudiantes de tu sistema
              if(data && data.length>0){
                setHorario(data);
              }else{
                setHorario([]); 
              }
            } catch (error) {
              console.error("Error al obtener horario:", error);
            }
            setShowSearch(true);
            setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
        }

    return (
        <div className="student-table-container p-3">
            <h1>Gestión de Horario</h1>
            <p>Esta es la vista para gestionar el horario de los estudiantes.</p>

            <div className="row">
                    <JornadaAnioPeriodoNivelCurso
                                callback={updateForm}
                                ref={filteReference}
                                loadTutoria={true}
                                loadCoordinators={true}
                                hidePeriodo={true}
                                properties={
                                    {
                                        className:'col-md-2 mb-2',
                                        id:'formReport2',
                                        filtro:false
                                    }
                                }
                            />
                        <div className="col-md-2 mb-1 margin-top-45">
                                <button onClick={BuscarHorario} className="btn btn-primary ml-2">Buscar</button>
                        </div>
            </div>

            {showLoading && <Loading />}

            {showSearch && (
            <> 
                    {!showLoading && (
                        <>
                            <div className="student-table-container p-3">
                                <div className="col-md-3 mb-3 d-flex align-items-end margin-top-48">
                                    <button className="btn-sm btn-Estudiante btn-NuevoEstudiante"  onClick={() => openAddingModal(null)} >
                                        Añadir Horario <i className="fa-solid fa-circle-plus"></i>
                                    </button>
                                </div>


                                <div className="horario-estudiante-container">
        
                                    <div className="scroll-semana">
                                        <div className="grid-semana">

                                            {(!horario || (horario && horario.length === 0)) && (<>
                                                <span>No se encontraron logros que mostrar, por favor añadelos</span>
                                            </>)}

                                            {horario?.map((dia) => (
                                                <div key={dia.dayName} className="columna-dia">
                                                <h3 className="titulo-dia">{dia.dayName}</h3>
                                                <div className="contenedor-clases">
                                                    {dia.schedule.map((clase, index) => (
                                                    <div key={index} className="tarjeta-clase">
                                                        <div className="">
                                                            <a className='btn btn-delete-horario btn-sm btn-danger btn-round' onClick={(e) => {e.preventDefault(); deleteHorarioById(clase.classScheduleId)}}> 
                                                                <i className="icon-deactivate fas fa-times" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                        <span className="nombre-asignatura">{clase.schoolSubjectName}</span>
                                                        <br/>
                                                        <span className="horario-clase">
                                                        {moment(clase.startHour, "HH:mm:ss").format("hh:mm A")} - {moment(clase.endHour, "HH:mm:ss").format("hh:mm A")}
                                                        </span>
                                                    </div>
                                                    ))}
                                                </div>
                                                </div>
                                            ))}
                                       </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}
                </>
            )}
          
 <>
            <MDBModal show={fullscreenXlModal} setShow={setFullscreenXlModal} tabIndex='-1'>
                <MDBModalDialog size='xl' fullscreen='xl-down'>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{selectedHorario ? "Editar Horario" : "Agregar Horario"}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {selectedHorario && (
                                <HorarioForm 
                                horario={selectedHorario} 
                                    onClose={()=>{
                                        toggleShow();
                                        BuscarHorario();
                                    }} 
                                />
                            )}

                            {!selectedHorario && (
                                <HorarioForm 
                                    horario={null} 
                                    onClose={()=>{
                                        toggleShow();
                                        BuscarHorario();
                                    }} 
                                />
                            )}
                        </MDBModalBody>
                        <MDBModalFooter>
                        <button type='button' className='btn-footer-close btn-primary' onClick={toggleShow}>
                                    Cerrar
                        </button>                            
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </>
        </div>
    );
};

export default Horario;
