import React, { useEffect, useState } from "react";
import AsignacionTutores from "../components/tutoresComponent.js";
import courseService from "../services/courseService.js";
import groupDirector from "../services/groupDirector.js";
import utils from '../utils/generalFunctions.js';
import docentesService from "../services/docentesService.js";
import collectionsService from "../services/collectionsService.js";
import academicService from "../services/academicService.js";

const Tutores = () => {
  const [courses,setCourses] = useState([]);
  const [directores,setDirectores] = useState([]);
  const [educationLevels,setEducationLevels] = useState([]);
  const [docentes,setDocentes] = useState([]);
  const [schoolYearId,setSchoolYearId] = useState([]);
  const [schoolYearList, setSchoolYearList] = useState([]);
  
  useEffect(() => {
    loadInformation();
  }, []);

  const loadInformation = async () => {
        try {
          await fetchSchoolYears();
          const parameters = await collectionsService.getParameters();
          const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
          if(currentAnio) setSchoolYearId(currentAnio.value);

          const docentesResp = await docentesService.getDocentes();
          setDocentes(docentesResp);
          const respCurso =  await courseService.getCursos();  // Asegúrate de tener un método getCursos en cursoService
          setCourses(respCurso);
          const allEducationLevels = utils.removeDuplicates(respCurso.map((c) => {return {educationLevelId: c.educationLevelId, educationLevelName: c.educationLevelName}}));
          setEducationLevels(allEducationLevels);
          await cargarDirectoresGrupo(null);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
  };

  const cargarDirectoresGrupo= async (schoolYearIdFilter) => {
      try {
        const respDirectores =  await groupDirector.getDirectorGrupos();
        const result = (schoolYearIdFilter)? respDirectores.filter(d=> d.schoolYearId===Number(schoolYearIdFilter)) : respDirectores.filter(d=> d.schoolYearId===Number(schoolYearId));
        setDirectores(result);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
  }

  const fetchSchoolYears = async () => {
      try {
          const yearsResp = await academicService.getYears();
          setSchoolYearList(yearsResp);
      } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
      }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSchoolYearId(value);
    cargarDirectoresGrupo(value);
};

  // Funciones para manejar cambios en las asignaciones de tutores
  const handleTutorChangePreescolar = (cursoId, tutorId) => {

    let added = false;
    let newDirectores = utils.copy(directores);
    const course = courses.find(c=>Number(c.courseId) === Number(cursoId));
    newDirectores.map(a=> {
            if(Number(a.courseId)===Number(cursoId)){
                a.changed = true;
                a.teacherId = tutorId;
                added=true;
            }
            return a;
    });
    if(!added){
      newDirectores.push({
        groupDirectorId:0,
        teacherId:tutorId,
        courseId:cursoId,
        gradeId:course.gradeId,
        schoolYearId:schoolYearId
      });
    }
    setDirectores(newDirectores);
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title">Asignación de Tutores</h5>
                <h6 className="card-subtitle text-muted">
                  Selecciona el año escolar.
                </h6>
              </div>
              <div className="card-body">
                        <div className="col-md-12 mb-12">
                          <label htmlFor="schoolYearId" className="form-label">Año escolar:</label>
                          <select className="form-control form-select" id="schoolYearId" name="schoolYearId"
                              value={schoolYearId} onChange={handleChange}>
                              <option value="0" >Seleccione</option>
                              {schoolYearList.map((schoolYear) => (
                                  <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                                      {schoolYear.year}
                                  </option>
                              ))}
                          </select>
                  </div>
              </div>
            </div>
      </div>
      {educationLevels.map(e=>{

        return(  
          <>
            {(courses.filter(c=>c.educationLevelId===e.educationLevelId && schoolYearList.find(sc=> Number(sc.schoolYearId) === Number(schoolYearId))?.journeyId===c.journeyId ).length>0) &&
            <>

              <div className="col-12 col-lg-6 col-xl-3">
                  <AsignacionTutores
                    titulo={e.educationLevelName}
                    cursos={courses.filter(c=>c.educationLevelId===e.educationLevelId && schoolYearList.find(sc=> Number(sc.schoolYearId) === Number(schoolYearId))?.journeyId===c.journeyId )  }
                    tutores={docentes}
                    asignaciones={directores}
                    handleTutorChange={handleTutorChangePreescolar}
                    loadTutores={cargarDirectoresGrupo}            
                  />
              </div>
            
            </> }
        </>);
      })}
    </div>
  );
};

export default Tutores;
