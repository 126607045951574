import api from './api';

const createClassSchedule = async (schedule) => {
    try {
        const response = await api.post(`schedule/`, schedule);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createClassScheduleList = async (schedule) => {
    try {
        const response = await api.post(`schedule/list`, schedule);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteClassScheduleById = async (id) => {
    try {
        const response = await api.delete(`schedule/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    createClassSchedule,
    createClassScheduleList,
    deleteClassScheduleById
};