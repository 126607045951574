import React, { useState, useEffect } from 'react';
import logroService from '../../services/logrosServices.js'; // Asegúrate de tener este servicio
import Swal from 'sweetalert2';
import Loading from '../Loading';
import academicService from '../../services/academicService.js';
import gradesService from '../../services/gradesService.js';
import asignaturaService from '../../services/asignaturaService.js';
import evaluativeScaleService from '../../services/evaluativeScaleService.js';
import procesoService from '../../services/procesoService.js';
import utils from "../../utils/generalFunctions.js";
import collectionsService from '../../services/collectionsService.js';
import types from '../../services/types.js';
import courseService from '../../services/courseService.js';
import scheduleService from '../../services/scheduleService.js';

const HorarioForm = (props) => {
    const { selectedHorario, onClose, ReadOnly } = props;
    const initData = {
        classScheduleId: 0,
        courseId: "",
        schoolSubjectId: [""],
        start: [""],
        end: [""],
        schoolYearId: "",
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);
    const [years, setYears] = useState([]);
    const [subjectsList, setSubjectsList] = useState([]);
    const [coursesList, setCoursesList] = useState([]);

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let item = utils.copy(formData);

        if(name.includes("schoolSubjectId")){    
            formData.schoolSubjectId[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        } else if(name.includes("start")){
            formData.start[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        }else if(name.includes("end")){
            formData.end[index] = value;
            setFormData(prevState => ({
                ...prevState,
               ...formData
            }));
        }else{
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
            item[name]=value;
        }
    };

    const validateInformationArray = () =>{
        for(let i=0; i < formData.schoolSubjectId.length; i++){
            if(!formData.schoolSubjectId[i]) return true;
            if(!formData.start[i]) return true;
            if(!formData.end[i]) return true;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields = ['courseId', 'schoolYearId'];
        for (let field of requiredFields) {
            if (!formData[field] || validateInformationArray()) {
                Swal.fire(
                    "Campos Obligatorios",
                    "Por favor, completa todos los campos obligatorios.",
                    "error"
                );
                return;
            }
        }

        if (!ReadOnly) {
            setShowLoading(true);
            try {
                const response = await scheduleService.createClassScheduleList(formData);
                if (response && response.message==="ok") {
                    Swal.fire(
                        "Felicidades!",
                        "Horario guardado con éxito!",
                        "success"
                    );
                    if (onClose) onClose();
                    setFormData(initData);
                } else {
                    Swal.fire(
                        "Ha ocurrido un error",
                        "Por favor intente más tarde",
                        "error"
                    );
                }
            } catch (error) {
                console.error('Error al enviar el formulario:', error);
                Swal.fire(
                    "Ha ocurrido un error",
                    "Por favor intente más tarde",
                    "error"
                );
            }
            setShowLoading(false);
        }
    };

    const fetchCollections = async () => {
        try {
            const yearsResp = await academicService.getYears();
            setYears(yearsResp);

            const parameters = await collectionsService.getParameters();
            const currentAnio = parameters.find((op) => String(op.keyName) === "AnioElectivo");
            if(currentAnio){
                setFormData(prevState => ({
                    ...prevState,
                    schoolYearId:currentAnio.value
                }));
            }

            const repCoursesList = await courseService.getCursos();
            setCoursesList(repCoursesList);

            const respAsignatura = await asignaturaService.getAsignaturas();
            setSubjectsList(respAsignatura);

        } catch (error) {
          console.error("Error al obtener listas de formulario:", error);
        }
    };

    const addHorario = () =>{
        formData.schoolSubjectId.push("");
        formData.start.push("");
        formData.end.push("");
        setFormData(prevState => ({
            ...prevState,
           ...formData
        }));
    }

    const removeItem = (index) =>{
        const newSchoolSubject = formData.schoolSubjectId.filter((p, i) => i !== index);
        const newStart = formData.start.filter((p, i) => i !== index);
        const newEnd = formData.end.filter((p, i) => i !== index);
        formData.evaluativeScaleId = newSchoolSubject;
        formData.evaluationProcessId = newStart;
        formData.description = newEnd;
        setFormData(prevState => ({
            ...prevState,
           ...formData
        }));
    }
    
 // Asegúrate de que el efecto para obtener un logro use la función correcta (getLogro en lugar de getLogroById)
 useEffect(() => {
    const fetchHorario = async () => {
        setShowLoading(true);
        await fetchCollections();
        if (selectedHorario) {
            try {
                const resClassSchedule = await logroService.getLogro(selectedHorario.classScheduleId); // Asegúrate de que este método existe y es correcto
                resClassSchedule.schoolSubjectId=[resClassSchedule.evaluativeScaleId];
                resClassSchedule.start=[resClassSchedule.evaluationProcessId];
                resClassSchedule.end=[resClassSchedule.description];
                setFormData((prevState) => ({ ...prevState, ...resClassSchedule }));
            } catch (error) {
                console.error("Error al obtener el logro:", error);
            }
        }
        setShowLoading(false);
    };

    fetchHorario();
}, [selectedHorario]);

return (
    <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
                <p className="text-muted">Por favor, completa todos los campos para registrar un nuevo logro.</p>
                <form onSubmit={handleSubmit} className="mt-3 row">
                    {/* Campos existentes ... */}

                    {/* Campo para Año Escolar */}
                    <div className="col-md-4 mb-3">
                        <label htmlFor="schoolYearId" className="form-label">Año escolar:*</label>
                        <select className="form-control form-select" 
                                id="schoolYearId"
                                name="schoolYearId"  
                                disabled={ReadOnly}
                                value={formData.schoolYearId} onChange={handleChange}>
                            <option value="">Seleccione</option>
                            {years.map((schoolYear) => (
                                <option key={schoolYear.schoolYearId} value={schoolYear.schoolYearId} >
                                    {schoolYear.year}
                                </option>
                            ))}
                        </select>
                    </div>

                     {/* Campo para Grado */}
                     <div className="col-md-4 mb-3">
                        <label htmlFor="courseId" className="form-label">Curso:*</label>
                        <select
                                className="form-control form-select validarForm"
                                name="courseId"
                                value={formData.courseId}
                                onChange={handleChange}
                                readOnly={ReadOnly}
                                required
                            >
                                <option value="" disabled={ReadOnly}>Seleccione</option>
                                {coursesList?.map((option) => (
                                <option
                                    key={option.courseId}
                                    value={option.courseId}
                                    disabled={ReadOnly}
                                >
                                    {option.name}
                                </option>
                                ))}
                            </select>
                    </div>

                    {/* Campo para Dia */}
                    <div className="col-md-4 mb-3">
                        <label className="form-label">Seleccione Día:*</label>
                        <select
                            className="form-control form-select validarForm"
                            name="dayNumber"
                            value={formData.dayNumber}
                            onChange={handleChange}
                            readOnly={ReadOnly}
                            required
                        >
                            <option value="" disabled={ReadOnly}>Seleccione</option>
                            <option value="1" disabled={ReadOnly}>Lunes</option>
                            <option value="2" disabled={ReadOnly}>Martes</option>
                            <option value="3" disabled={ReadOnly}>Miercoles</option>
                            <option value="4" disabled={ReadOnly}>Jueves</option>
                            <option value="5" disabled={ReadOnly}>Viernes</option>
                            <option value="6" disabled={ReadOnly}>Sabado</option>
                        </select>
                    </div>

                    <div className="col-md-12 mb-12">
                        <table className='table table-bordered table-sm table-striped my-0'>
                                <thead>
                                    <tr>
                                        <th colSpan={4}>
                                        <button type='button' className='btn btn-success' onClick={addHorario}>
                                            Añadir <i class="fa-sharp fa-solid fa-plus" ></i>
                                        </button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Asignatura</th>
                                        <th>Inicio</th>
                                        <th>Final</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {formData.schoolSubjectId.map((scale,index) => (
                                    <>
                                        <tr>
                                            <td>
                                                <select
                                                        className="form-control form-select"
                                                        id={"schoolSubjectId"+index}
                                                        name={"schoolSubjectId"+index}
                                                        disabled={ReadOnly}
                                                        value={formData.schoolSubjectId[index]} onChange={(e)=> {handleChange(e, index) }}>
                                                        <option value="" disabled>Seleccione una asignatura</option>
                                                        <option value="null">DESCANSO</option>
                                                        {subjectsList.map((subject) => (
                                                            <option key={subject.schoolSubjectId} value={subject.schoolSubjectId}>
                                                                {subject.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </td>
                                            <td>
                                                <input type="time" list="meal-times" readOnly={ReadOnly} className="form-control" id={"start"+index} name={"start"+index} placeholder="Hora de Inicio" 
                                                value={formData.start[index]} onChange={(e)=> {handleChange(e, index) }} required/>
                                            </td>
                                            <td>
                                                <input type="time" list="meal-times" readOnly={ReadOnly} className="form-control" id={"end"+index} name={"end"+index} placeholder="Hora de Fin" 
                                                value={formData.end[index]} onChange={(e)=> {handleChange(e, index) }} required/>
                                            </td>
                                            <td>
                                               { !(index===0 && (selectedHorario?.classScheduleId > 0)) && (

                                                <button
                                                    onClick={() => removeItem(index)}
                                                    className="btn-deactivate btn-sm"
                                                    title="Eliminar"
                                                >
                                                    <i className="icon-deactivate fas fa-times"></i>
                                                </button>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                        </table>
                    </div>

                     

                    {/* Campo para Descripción */}
                    {/* <div className="col-md-12 mb-3">
                        <label htmlFor="description" className="form-label">Descripción:*</label>
                        <textarea readOnly={ReadOnly} className="form-control" id="description" name="description" placeholder="Ingrese la descripción" value={formData.description} onChange={handleChange} required />
                    </div> */}

                    {/* ... Resto de campos existentes ... */}

                    {!ReadOnly && (
                        <div className='row'>
                            <div className='col-md-12 text-center mt-5'>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        )}
    </>
);
}

export default HorarioForm;