import React, { useEffect, useRef, useState } from 'react';
import Cardacademy  from '../../components/CategoryCard.js';
import DashboardCard from '../../components/TituloDashboard.js'; // Asegúrate de que la ruta sea correcta
import studentPhoto from '../../img/user-profile.png';
import util from '../../utils/generalFunctions.js'




  const DashboardEstudiantePc = () => {

    const studentInfo = util.getStudentInformation();

        const chartContainerStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%',
            padding: '10px'
        };

  return (
    
    <div style={chartContainerStyle} className='row '>
                  <DashboardCard
          studentPhoto={studentPhoto} // Usar la variable importada aquí
          title={"Te damos la Bienvenida, "+(studentInfo?.firstName??'')+"!"}
          subtitle="Dashboard school"
        />
            
        <div className="text-end mb-3">
            <div className="text-start float-inline-start">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc6jLYQU9WZLXkHfdjb8rV6FAgwfVJR5FSWpK0Me9RPmajmsg/viewform" target="blank"
                    className="btn btn-success me-2">Solicitud de cupo</a>

                    <a href="https://forms.gle/kD9pEuLhaqfA9MoP6" target="blank"
                    className="btn btn-info me-2">Reporte de inasistencia</a>

                    <a href="https://docs.google.com/forms/u/6/d/e/1FAIpQLScNEKWvyteX9iHKfibGhos7dshx_LC91gC5RPHLSz1w3Un_cA/viewform" target="blank"
                    className="btn btn-primary me-2 m-margin-top-10">Recibido entrega de informes</a>
            </div>
        </div>
      <div className="cardcate">
        <Cardacademy title="Hoja de Vida" icon="fa-user" link="/dashboard-estudiantepc/perfil" className="grades-card" /> 
        {studentInfo?.educationLevelId!==1 && (
          <Cardacademy title="Calificaciones" icon="fa-chart-simple" link="/dashboard-estudiantepc/Notas" className="students-card"/>
        )}        
        {studentInfo?.educationLevelId===1 && (
          <Cardacademy title="Logros" icon="fa-solid fa-award" link="/dashboard-estudiante/calificaciones-estudiante-pre" className="students-card"/> 
        )}        
        <Cardacademy title="Estado de Cuenta" icon="fa-solid fa-coins" link="/dashboard-estudiantepc/estado-de-cuenta" className="students-card"/>
        <Cardacademy title="Seguimiento" icon="fa-person-circle-exclamation" link="/dashboard-estudiantepc/observador-asistencia" className="students-card"/>
        <Cardacademy title="Horario" icon="fa-calendar-days" link="/dashboard-estudiantepc/horario-de-clases" className="students-card"/>
        <Cardacademy title="Reportes" icon="fa-solid fa-chart-pie" link="/reportes-estudiantepc" className="students-card"/>


        {/* Puedes añadir más categorías según sea necesario */}
      </div>


     </div>      
      
  );
};

export default DashboardEstudiantePc;
